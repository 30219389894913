<template>
    <div>
        <!-- <div class="flex items-center">
            <p class="text-[1rem] font-[600] mr-2">Please select required options:</p>
            <v-btn-toggle v-model="serverType" color="deep-purple-accent-3" group mandatory rounded="0"
                style="height: 40px;" class="border">
                <v-btn value="XProxy" class=" bg-white text-black h-full" style="outline: none; height: 100%;">
                    <img src="../../../assets/openVPN-not.png" />
                    <p class="text-xs pl-2">HTTP & SOCKS5</p>
                </v-btn>
                <v-btn value="ProxySmart" class=" bg-white text-black h-full" style="outline: none; height: 100%;">
                    <img src="../../../assets//openVPN.png" />
                    <p class="text-xs pl-2">HTTP & SOCKS5 With OpenVPN</p>
                </v-btn>
            </v-btn-toggle>
        </div> -->
        <p class="font-bold py-2">Country</p>
        <div class="overflow-hidden bg-white rounded-0 border-0 p-20">
            <div v-for="country in countryList" v-bind:key="country"
                @click="selectCountry(country.name, country.proxy_count)" class="cursor-pointer float-left text-black"
                rounded="0">
                <div class=" justify-between items-center p-3">
                    <div class="relative rounded  shadow-md   hover:scale-110  duration-500  border border-black"
                        :class="selectedDetails.country === country.name ? 'scale-110 shadow-blue-500' : selectedDetails.country ? ' contrast-75' : ''">
                        <img :src="country.iconurl" class="relative rounded" width="120" height="100" />
                        <span
                            class="position-absolute z-10 start-100 text-[16px] min-w-[30px] top-0 translate-middle badge rounded-pill flex items-center justify-center aspect-square"
                            :class="(Number(country.x_proxy) + Number(country.proxysmart)) > 0 ? 'bg-success' : 'bg-danger'">
                            {{ (Number(country.x_proxy) + Number(country.proxysmart)) }}
                        </span>
                        <div
                            class="w-full flex px-2 absolute bottom-0 rounded-b border-t bg-gray text-xs py-1 font-bold z-0">
                            <div class="mx-auto px-2" style="font-size: 10px;">
                                {{ country.name }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <p class="text-[10px] text-center">HTTP & SOCKS5: {{ country.x_proxy }}</p>
                <p class="text-[10px] text-center">HTTP & SOCKS5 /W VPN: {{ country.proxysmart }}</p> -->
            </div>
        </div>
        <div v-if="openvpn" class="text-[1rem] flex items-center py-3">
            <p class="pr-4 text-[1.3rem]">Need OpenVPN Connection for UDP Support or OS / IP Fingerprint Spoofing?</p>
            <div class="flex items-center">
                <input v-model="serverType" 
                    type="radio" 
                    value="ProxySmart"
                    class="cursor-pointer"
                    name="yes" /> 
                <label for="yes" @click="smart_click" class="cursor-pointer p-2 peer-checked:bg-blue-500 peer-checked:text-white peer-checked:border-blue-500 transition">YES</label> 
            </div>
            <div class="flex items-center">
                <input v-model="serverType" 
                    type="radio" 
                    value="XProxy" 
                    class="cursor-pointer"
                    name="no" /> 
                <label for="no" @click="x_click" class="cursor-pointer p-2 peer-checked:bg-blue-500 peer-checked:text-white peer-checked:border-blue-500 transition">NO</label> 
            </div>
        </div>
        <div v-if="serverType" class="flex space-x-2 pb-4">
            <div class="w-1/2">
                <p class="font-bold py-2">ISP</p>
                <div class="overflow-hidden bg-white rounded-0 border-10 p-20">

                    <div v-for="isp in ispList" v-bind:key="isp" @click="selectedISP = isp.isp"
                        class="cursor-pointer float-left box-border" rounded="0">
                        <div class=" justify-between  items-center p-3 text-black">
                            <div class="relative rounded  shadow-md w-full  hover:scale-110  duration-500  border border-black"
                                :class="selectedDetails.isp === isp.isp ? 'scale-110 shadow-blue-500' : selectedDetails.isp ? 'opacity-50 ' : ''">
                                <img :src="isp?.isp_image ? backendUrl + isp?.isp_image : 'https://webcolours.ca/wp-content/uploads/2020/10/webcolours-unknown.png'"
                                    class="relative rounded-t object-cover" style="aspect-ratio: 1/1;" width="100"
                                    height="100">
                                <span
                                    class="position-absolute top-full start-100 translate-middle badge rounded-pill flex items-center justify-center aspect-square"
                                    :class="isp.proxy_count > 0 ? 'bg-success' : 'bg-danger'">
                                    {{ isp.proxy_count }}
                                </span>
                                <div
                                    class="w-full flex justify-between px-3 bottom-0 rounded-b  z-10 bg-gray text-xs py-1 font-bold">
                                    <div class="" style="font-size: 10px;">
                                        {{ isp.isp }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="font-bold py-2">City</p>
                <div class="bg-white p-20 lg:flex gap-x-2 flex-wrap">
                    <div v-for="city in cityList" v-bind:key="city" class=" w-full py-2 lg:w-fit flex justify-center">
                        <button type="button"
                            class="btn py-1 px-4 btn-primary rounded-full text-center relative hover:scale-110"
                            @click="selectCity(city.city, city.count, city.modem_id)"
                            :class="selectedDetails.city === city.city ? 'scale-110 shadow-blue-500' : selectedDetails.city ? 'opacity-50 ' : ''"
                            style="font-size: 10px;">
                            <p>{{ city.city }}</p>
                            <span
                                class="position-absolute top-100 start-100 translate-middle badge rounded-pill flex items-center justify-center aspect-square z-10"
                                :class="city.count > 0 ? 'bg-success' : 'bg-danger'">
                                {{ city.count }}
                            </span>
                        </button>

                    </div>
                </div>
            </div>
            <div class="w-1/2">
                <p class="font-bold py-2">Package</p>
                <div class="bg-white p-10"
                    v-if="selectedDetails.country && selectedDetails.isp && selectedDetails.city && selectedDetails.modems_id.length > 0">
                    <div class="md:flex justify-center">
                        <div v-if="plans.daily !== null"
                            class="sm:w-1/2 lg:w-52  mx-auto p-3 hover:scale-110 cursor-pointer duration-500  "
                            :class="selectedPlan === 'daily' ? 'scale-110' : ''" @click="selectedPlan = 'daily'">
                            <div class="card">
                                <img :src="ispList.find(isp => isp.isp === selectedDetails.isp) && ispList.find(isp => isp.isp === selectedDetails.isp)?.isp_image ?
                                    backendUrl + ispList.find(isp => isp.isp === selectedDetails.isp).isp_image :
                                    `https://webcolours.ca/wp-content/uploads/2020/10/webcolours-unknown.png`"
                                    class="w-full aspect-square object-cover brightness-50 rounded-md  " />
                                <div class="absolute w-full h-full top-0 text-black border border-black shadow-md
                                 rounded-md duration-500  bg-package-card  flex items-center justify-center font-bold"
                                    :class="selectedPlan === 'daily' ? 'shadow-blue-500' : ''">
                                    <div class="text-xs xl:text-[14px] 2xl:text-[16px]" style="line-height: normal;">
                                        <!-- <p class="text-center">{{ plans.daily.name }}</p> -->
                                        <p class="text-center">${{ plans.daily.price }} : Daily</p>
                                        <p class="text-center">{{ selectedDetails.country }} {{ selectedDetails.city }}</p>
                                        <p class="text-center" v-if="plans.daily?.data_limit">Max Data per Day: {{ plans.daily.data_limit }}GB</p>
                                        <p class="text-center" v-if="plans.daily?.rotation_time">Minimum IP Rotation Time: {{ plans.daily.rotation_time }}mins</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="plans.weekly !== null"
                            class="sm:w-1/2 lg:w-52  mx-auto p-3 hover:scale-110 cursor-pointer duration-500  "
                            :class="selectedPlan === 'weekly' ? 'scale-110' : ''" @click="selectedPlan = 'weekly'">
                            <div class="card flex ">
                                <img :src="ispList.find(isp => isp.isp === selectedDetails.isp)?.isp_image ?
                                    backendUrl + ispList.find(isp => isp.isp === selectedDetails.isp)?.isp_image :
                                    `https://webcolours.ca/wp-content/uploads/2020/10/webcolours-unknown.png`"
                                    class="w-full aspect-square object-cover brightness-50 rounded-md  " />
                                <div class="absolute w-full h-full top-0 text-black border border-black shadow-md
                                 rounded-md duration-500  bg-package-card  flex items-center justify-center font-bold"
                                    :class="selectedPlan === 'weekly' ? 'shadow-blue-500' : ''">
                                    <div class="text-xs xl:text-[14px] 2xl:text-[16px]" style="line-height: normal;">
                                        <!-- <p class="text-center">{{ plans.weekly.name }}</p> -->
                                        <p class="text-center">${{ plans.weekly.price }} : Weekly</p>
                                        <p class="text-center">{{ selectedDetails.country }} {{ selectedDetails.city }}</p>
                                        <p class="text-center" v-if="plans.weekly?.data_limit">Max Data per Day: {{ plans.weekly.data_limit }}GB</p>
                                        <p class="text-center" v-if="plans.weekly?.rotation_time">Minimum IP Rotation Time: {{ plans.weekly.rotation_time }}mins</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="plans.monthly !== null"
                            class="sm:w-1/2 lg:w-52  mx-auto p-3 hover:scale-110 cursor-pointer duration-500  "
                            :class="selectedPlan === 'monthly' ? 'scale-110' : ''" @click="selectedPlan = 'monthly'">
                            <div class="card">
                                <img :src="ispList.find(isp => isp.isp === selectedDetails.isp)?.isp_image ?
                                    backendUrl + ispList.find(isp => isp.isp === selectedDetails.isp)?.isp_image :
                                    `https://webcolours.ca/wp-content/uploads/2020/10/webcolours-unknown.png`"
                                    class="w-full aspect-square object-cover brightness-50 rounded-md  " />
                                <div class="absolute w-full h-full top-0 text-black border border-black shadow-md
                                 rounded-md duration-500  bg-package-card  flex items-center justify-center font-bold"
                                    :class="selectedPlan === 'monthly' ? 'shadow-blue-500' : ''">
                                    <div class="text-xs xl:text-[14px] 2xl:text-[16px]" style="line-height: normal;">
                                        <!-- <p class="text-center">{{ plans.monthly.name }}</p> -->
                                        <p class="text-center">${{ plans.monthly.price }} : Monthly</p>
                                        <p class="text-center">{{ selectedDetails.country }} {{ selectedDetails.city }}</p>
                                        <p class="text-center" v-if="plans.monthly?.data_limit">Max Data per Day: {{ plans.monthly.data_limit }}GB</p>
                                        <p class="text-center" v-if="plans.monthly?.rotation_time">Minimum IP Rotation Time: {{ plans.monthly.rotation_time }}mins</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full flex justify-center mt-4">
                        <button type="button" class="btn btn-primary w-1/2 mt-4"
                            :disabled="!(selectedDetails.country && selectedDetails.isp && selectedDetails.city && selectedPlan)"
                            data-bs-toggle="modal" data-bs-target="#purchaseModal">Purchase</button>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div>
        <PurchaseModal :selected-details="selectedDetails" :plan="selectedPlan && plans[selectedPlan]" :selected-server-type="serverType" :openVPN="openVPNTemp" />
    </div>
</template>

<script setup>

import { ref, watch, onMounted } from "vue";
import { usePackageStore, useAdminConfigurationStore } from "@/stores"
import { watchEffect } from "vue";
import { storeToRefs } from "pinia";
import PurchaseModal from "@/components/Customer/BuyNewProxy/PurchaseModal.vue"
const backendUrl = process.env.VUE_APP_VUE_SOCKET_URL + "/"
const selectedDetails = ref({ country: null, isp: null, city: null, modems_id: [] });
const adminConfigurationStore = useAdminConfigurationStore();
const packageStore = usePackageStore()
const { countryList, plans } = storeToRefs(packageStore)
const ispList = ref([])
const cityList = ref([])
const openVPNTemp = true;

const selectedISP = ref(null)
const selectedPlan = ref(null)

const serverType = ref("XProxy");
// await packageStore.getCountryList(serverType.value)

const x_click = () => {
    serverType.value = "XProxy";
}

const smart_click = () => {
    serverType.value = "ProxySmart";
}

watchEffect(async () => {
    await adminConfigurationStore.getOpenvpn()
})

const { openvpn } = storeToRefs(adminConfigurationStore)

const selectCountry = (countryName, proxy_count) => {
    // if (proxy_count > 0)
    if (selectedDetails.value.country === countryName) {
        selectedDetails.value.country = null
    }
    else {
        selectedDetails.value.country = countryName
    }
}

const selectCity = (cityName, proxy_count, modem_id) => {
    // if (proxy_count > 0)
    selectedDetails.value.city = cityName;
    selectedDetails.value.modems_id = modem_id;
}
onMounted(async () => {
    await packageStore.getCountryList(serverType.value)
    selectedDetails.value.country = countryList.value[0]?.name
});
watchEffect(async () => {
    if (countryList.value?.length === 0){
        await packageStore.getCountryList(serverType.value)
        // countryList.value[1] = countryList.value[0];
        // countryList.value[0] = temp;
        // [countryList.value[0], countryList.value[1]] = [countryList.value[1], countryList.value[0]]
        selectedDetails.value.country = countryList.value[0]?.name
    }

}, [countryList.value])
watch(
    () => selectedDetails.value.country,
    async (newCountry, oldCountry) => {
        const newServer = serverType.value; // get the current value of serverType
        if (newCountry) {
            let ispListTemp = await packageStore.getISPList(newCountry, newServer);
            if (newServer === "XProxy") {
                const proxysmartISP = await packageStore.getISPList(newCountry, 'ProxySmart');
                ispListTemp = proxysmartISP?.map((isp, index) => {
                    return {
                        isp: isp.isp,
                        proxy_count: Number(isp.proxy_count) + Number(ispListTemp[index].proxy_count),
                        isp_image: isp.isp_image
                    };
                });
            }
            ispList.value = ispListTemp;

            if (ispList.value && ispList.value?.length > 0) {
                selectedDetails.value.isp = ispList.value[0].isp;
                selectedISP.value = ispList.value[0].isp;
            } else {
                selectedDetails.value.isp = null;
                selectedISP.value = null;
            }
        } else {
            ispList.value = [];
            cityList.value = [];
        }
    },
    { immediate: true }
);

watch(
    () => serverType.value,
    async (newServer, oldServer) => {
        const newCountry = selectedDetails.value.country; // get the current value of country
        if (newCountry) {
            let ispListTemp = await packageStore.getISPList(newCountry, newServer);
            if (newServer === "XProxy") {
                const proxysmartISP = await packageStore.getISPList(newCountry, 'ProxySmart');
                ispListTemp = proxysmartISP?.map((isp, index) => {
                    return {
                        isp: isp.isp,
                        proxy_count: Number(isp.proxy_count) + Number(ispListTemp[index].proxy_count),
                        isp_image: isp.isp_image
                    };
                });
            }
            ispList.value = ispListTemp;

            if (ispList.value && ispList.value?.length > 0) {
                selectedDetails.value.isp = ispList.value[0].isp;
                selectedISP.value = ispList.value[0].isp;
            } else {
                selectedDetails.value.isp = null;
                selectedISP.value = null;
            }
        } else {
            ispList.value = [];
            cityList.value = [];
        }
    },
    { immediate: true }
);


watchEffect(async () => {
    if (selectedISP.value) {
        const tempISP = selectedISP.value
        selectedDetails.value.isp = tempISP
        if (selectedDetails.value.country && tempISP) {
            cityList.value = await packageStore.getCityList(selectedDetails.value.country, tempISP, serverType.value);
            if(serverType.value === "XProxy" ){
                const proxysmartCity = await packageStore.getCityList(selectedDetails.value.country, tempISP, "ProxySmart");
                const len = cityList.value?.length;
                proxysmartCity?.map(city => {
                    if(len !== 0){
                        let flag = 0;
                        for(let i = 0; i < len; i++) {
                            if(cityList.value[i].city.trim() === city.city.trim()) {
                                flag = 1;
                                cityList.value[i].count = Number(cityList.value[i].count) + Number(city.count);
                                city?.modem_id?.map(id => {   
                                    cityList.value[i].modem_id.push(id)
                                })

                                
                            } 
                        }
                        if(flag === 0){
                            cityList.value.push(city);
                        }
                    }
                    else {
                        cityList.value.push(city)
                    }
                })
            }
            console.log("selectedIsP")
            if (cityList.value && cityList.value?.length > 0){
                console.log(cityList.value[0])
                selectedDetails.value.city = cityList.value[0].city;
                selectedDetails.value.modems_id = cityList.value[0].modem_id
            }
            else {
                selectedDetails.value.city = null
            }
        }
    }
}, [selectedISP.value])
watchEffect(() => {
    if (selectedDetails.value.country && selectedDetails.value.isp && selectedDetails.value.city && selectedDetails.value.modems_id.length > 0) {
        // emit("detailChoosed", selectedDetails.value)
        packageStore.getPlans(selectedDetails.value.country, selectedDetails.value.isp, selectedDetails.value.city, selectedDetails.value.modems_id).then((res) => {
            if (res){
                selectedPlan.value = Object.keys(res).filter(key => res[key] !== null)[0]
            }
        })
    }
}, [selectedDetails.value.city])

// watchEffect(()=>{
//     packageStore.getCountryList(serverType.value)
// },[serverType.value])
</script>